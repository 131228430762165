import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "react-multi-carousel/lib/styles.css"
// Hero Section
const Hero = () => (
  <section className="grid 2xl:h66vh">
    <StaticImage
      src={"../../images/customer-at-do-it-best-industrial-supply.jpg"}
      alt=""
      aspectRatio={16 / 9}
      placeholder={"tracedSVG"}
      className="grid grid-1-1"
      style={{
        gridArea: "1/1",
      }}
      layout="fullWidth"
    />
    <div className="relative grid opacity-75 bg-gradient-to-l from-yellow-400 to-red-600 bg-opacity-90 grid-1-1" />
    <div className="relative grid grid-1-1 place-items-center ">
      <div className="w-full py-4 bg-gray-800 bg-opacity-90">
        <h1 className="m-auto text-2xl font-semibold text-center text-transparent uppercase md:text-3xl bg-gradient-to-r from-yellow-400 to-red-600 bg-opacity-90 bg-clip-text fit-content">
          We are <em>Do It Best Industrial Supply</em>
        </h1>
      </div>
    </div>
  </section>
)

const AboutUs = () => (
  <section className="py-8 bg-gray-100">
    <div className="container flex flex-col px-2 pt-4 pb-12 mx-auto text-gray-800">
      <StaticImage
        src={"../../images/dibis/Do It Best Logo Wide.png"}
        alt="Do It best Industrial Supply Logo"
        placeholder={"blurred"}
        className="self-center max-w-screen-md"
        imgClassName="w-full"
      />
      <div className="container flex flex-row-reverse flex-wrap px-5 py-12 mx-auto sm:flex-nowrap">
        {/* Map & Address Stuff */}
        <div className="relative flex items-end justify-start p-10 overflow-hidden bg-gray-300 rounded-lg lg:w-2/3 md:w-1/2 sm:mr-10">
          <iframe
            width="100%"
            height="100%"
            className="absolute inset-0"
            frameBorder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            loading="lazy"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%C4%B0zmir+(Do%20It%20Best%20Industrial%20Supply)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
            style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
          ></iframe>
          <div>
            <h2 className="mb-1 text-center text-white bg-gray-800 rounded text-shadow">
              Contact Us
            </h2>
            <address className="relative flex flex-wrap min-w-full py-6 bg-white rounded shadow-md">
              <div className="px-6 lg:w-1/2">
                <h3 className="text-xs font-semibold tracking-widest text-gray-900 title-font">
                  ADDRESS
                </h3>
                <p className="mt-1">
                  19310 HWY 43 <br />
                  Mt Vernon, AL 36560
                </p>
              </div>
              <div className="px-6 mt-4 lg:w-1/2 lg:mt-0">
                <h3 className="text-xs font-semibold tracking-widest text-gray-900 title-font">
                  EMAIL
                </h3>
                <a
                  href="mailto:industrialsupply.llc.com@gmail.com"
                  className="leading-relaxed tracking-tight text-red-500"
                >
                  industrialsupply.llc.com@gmail.com
                </a>
                <h3 className="mt-4 text-xs font-semibold tracking-widest text-gray-900 title-font">
                  PHONE
                </h3>
                <p className="leading-relaxed">251-829-4591</p>
              </div>
              <div className="w-full px-6 mt-4 lg:mt-0">
                <h3 className="mt-4 text-xs font-semibold tracking-widest text-gray-900 title-font">
                  Hours
                </h3>
                <div className="flex justify-between">
                  <span>M-F: 7-5</span>
                  <span>Sat: 8-3</span>
                  <span>Sun: Closed</span>
                </div>
              </div>
            </address>
          </div>
        </div>
        {/* Side Content */}
        <div className="flex flex-col w-full px-3 mt-8 bg-white rounded-lg lg:w-1/3 md:w-1/2 md:ml-auto md:py-8 md:mt-0">
          <h2 className="mb-1 text-lg font-medium text-gray-900 title-font">
            Who we are
          </h2>
          <p className="mb-5 leading-relaxed text-gray-600">
            We are a <em>Do It Best</em> member-owned hardware, lumber, and
            building material store. From our base in Calvert, Alabama, we serve
            the growing residential, commercial, and industrial markets in North
            Mobile County.
          </p>
        </div>
      </div>
      {/* Bottom Content */}
      <div className="flex flex-col px-5 mx-5 mt-8 bg-white rounded-lg md:py-8 md:mt-0">
        <strong className="mb-4 text-3xl font-medium text-center text-transparent bg-gradient-to-r from-yellow-400 to-red-600 bg-opacity-90 bg-clip-text title-font">
          Hardware & supply made easy
        </strong>
        <StaticImage
          src={"../../images/Scott-Shoemaker.jpg"}
          alt="Do It best Industrial Supply Logo"
          placeholder={"tracedSVG"}
          className="self-center max-w-screen-md rounded shadow-md"
          imgClassName="w-full rounded shadow-md"
        />
        <p className="mt-6 mb-5 leading-relaxed text-gray-600">
          We have over 40 years of experience in the hardware & industrial
          supply business. We offer great prices and excellent customer service.
          We expect our customers to experience polite customer service at it's
          finest while shopping with us. We expect our industrial and
          construction clients to experience a streamlined purchasing process
          that's accurate and timely. Our goal is be a fixture of the community
          that you can count on for your everyday hardware needs.
        </p>
        <p className="mb-5 leading-relaxed text-gray-600">
          Our secret to success is that we love what we do. We sell a vast range
          of products and items that make our customers lives easier. We do not
          want you to have to drive to Mobile to get the products you need. We
          want to be your store of choice for all your household, work, and
          community needs.
        </p>
        <p className="mb-5 leading-relaxed text-gray-600">
          We put a strong emphasis on being the go to store for all your
          hardware and supply related needs, because we love selling a wide
          range of products and items that make our customer's lives easier, and
          their jobs run smoother. We value our customers above all else, and
          take pride in our ability to provide world class service at the local
          level.
        </p>
      </div>
    </div>
  </section>
)

const About = ({ children }) => {
  return (
    <>
      <Hero />
      <AboutUs />
    </>
  )
}

export default About
